<template>
  <div id="app">
    <MyHome/>
  </div>
</template>

<script>
import MyHome from './components/MyHome.vue'

export default {
  name: 'App',
  components: {
    MyHome
  }
}
</script>

<style lang="scss">

</style>
