<template>
  <div class="home">
    <van-swipe class="my-swipe" vertical :loop="false" height="100vh" :duration="500" @change="swiperChange" catchMove>
      <van-swipe-item>
        <div class="item_one item">
          <div class="item_one-warp">
            <img width="100%" height="auto" src="@/assets/Frame144.png" />
            <img class="text" src="@/assets/text.png" alt="" />
            <img class="text_two" src="@/assets/to p.png" alt="" />
            <div class="btn-box">
              <div class="btn" v-for="(item, index) in funBtnList" :key="index" @click="open(item.url)">
                <img class="btn_image" :src="item.icon" alt="" />
              </div>
            </div>
            <div class="downLoadBtn-box">
              <div class="downLoadBtn" @click="open('https://apps.apple.com/us/app/id6499410998')">
                <img src="@/assets/Group 3724.png" alt="" />
              </div>
              <div class="downLoadBtn" @click="show = true">
                <img src="@/assets/Group 3727.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="item_other item">
          <img width="100%" height="auto" src="@/assets/Frame 145.png" />
          <img width="100%" class="image_center" src="@/assets/Group 3764.png" alt="" />
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="item_other item">
          <img width="100%" height="auto" src="@/assets/Frame 145.png" />
          <div width="100%" class="image_center">
            <img src="@/assets/Group 3731.png" style="margin: 0 auto; width: 90%; display: block" alt="" />
            <img src="@/assets/Group 3769.png" class="two_center" alt="" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <!-- <van-list style="height:100vh'"> -->

        <div class="item_other item">
          <img width="100%" height="auto" src="@/assets/Frame 145.png" />
          <img width="100%" class="image_center" src="@/assets/four.png" alt="" />
        </div>

        <!-- </van-list> -->
      </van-swipe-item>
      <van-swipe-item>
        <img src="@/assets/Frame 143.png" width="100%" alt="" />
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">
          <div :class="['dot', current === item - 1 ? 'dot-current' : '']" v-for="item in 5" :key="item"></div>
        </div>
      </template>
    </van-swipe>

    <div class="more" v-if="current < 3">
      <img src="@/assets/Frame.png" class="more-icon" alt="" />
    </div>

    <van-dialog v-model="show" :show-confirm-button="false" width="220px" close-on-click-overlay @close.native="show = false">
      <img style="width: 220px" src="@/assets/Frame 141.png" />
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      current: 0,
      show: false,
      funBtnList: [
        {
          icon: require('@/assets/btn1.png'),
          url: 'https://bd.douyusm.com',
        },
        {
          icon: require('@/assets/bnt2.png'),
          url: 'https://www.douyusm.cn',
        },
        {
          icon: require('@/assets/btn3.png'),
          url: '',
        },
      ],
    }
  },
  methods: {
    swiperChange(e) {
      // console.log(e)
      this.current = e
    },
    open(url) {
      if(url) {
        window.location.href = url
      }else{
        this.show = true
      }
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .van-dialog {
  background-color: transparent;
}
.home {
  overflow-y: scroll;
}
.my-swipe {
  height: 100vh;
  .item {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .item_one {
    background-image: url('../assets/image-281.png');
    background-size: cover;
    background-position: center;
    &-warp {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background-color: rgba(0, 0, 0, 0.4);
      .text {
        width: 80%;
        margin-top: 68px;
      }
      .text_two {
        width: 38%;
        margin-top: 12px;
      }
      .btn-box {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .btn {
          width: 30%;
        }
        .btn_image {
          width: 100%;
        }
      }
      .downLoadBtn-box {
        position: absolute;
        bottom: 115px;
        left: 50%;
        transform: translateX(-50%);
      }
      .downLoadBtn {
        width: 148px;
        img {
          width: 100%;
        }
      }
      .downLoadBtn + .downLoadBtn {
        margin-top: 18px;
      }
    }
  }
  .item_other {
    background-image: url('../assets/bg.png');
    background-size: cover;
    background-position: center;
    height: 100%;
    position: relative;
    .image_center {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      .two_center {
        width: 72.5%;
        margin-top: 11px;
        margin-left: 21.5%;
      }
    }
  }
}
.more {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
  img {
    width: 32px;
  }
}
.item_bottom {
  position: absolute;
  height: 100%;
  overflow-y: scroll;
}
.custom-indicator {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: rgba(198, 198, 198, 1);
    margin-bottom: 8px;
    transition: height 0.5s;
  }
  .dot-current {
    height: 22px;
    transition: height 0.5s;
    background: linear-gradient(180deg, #af6aef 0%, #885bf3 100%);
  }
}
</style>
